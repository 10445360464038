.SideDrawer {
	width: 67%;
	max-width: 70%;
	min-width: 150px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	-webkit-transition: -webkit-transform 0.5s ease-out;
	transition: -webkit-transform 0.5s ease-out;
	transition: transform 0.5s ease-out;
	transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-flow: column;
	-webkit-box-align: center;
	align-items: center;
	border: 5px solid #eee;
	border-bottom: 20px #eee;
	z-index: 300;
	background-color: #14b4f3;
	background-image: url(../../assets/images/Responsive_Application_1_compressed.jpg);
	background-size: cover;
	background-position: 36% 0%;
}

.Open {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
.Close {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}
.H1Tags {
	margin: 2px 0px 10px 0px;
	color: #000;
}
.Nav {
	width: 50%;
}
@media (min-width: 680px) {
	.SideDrawer {
		display: none;
	}
}
