.CubeContainer {
	width: 150px;
	height: 150px;
	-webkit-perspective: 800px;
	perspective: 800px;
	-webkit-perspective-origin: 400px;
	perspective-origin: 400px;
	position: relative;
	z-index: 3;
}

.Cube {
	width: 150px;
	height: 150px;
	-webkit-animation-name: rotate;
	animation-name: rotate;
	-webkit-animation-duration: 10s;
	animation-duration: 10s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0.02;
	animation-delay: 0.02;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.Cube div {
	position: absolute;
	z-index: 3;
	width: 150px;
	height: 150px;
	font-weight: bold;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	text-align: center;
	border: 1px solid #000;
}

.FrontSide {
	-webkit-transform: translateZ(75px);
	transform: translateZ(75px);
	background-color: #f50606;
	font-size: 26px;
}

.BackSide {
	-webkit-transform: rotateY(180deg) translateZ(75px) rotateZ(180deg);
	transform: rotateY(180deg) translateZ(75px) rotateZ(180deg);
	background-color: #f50606;
	font-size: 20px;
}

.LeftSide {
	-webkit-transform: rotateY(-90deg) translateZ(75px);
	transform: rotateY(-90deg) translateZ(75px);
	background-color: #f50606;
}

.RightSide {
	-webkit-transform: rotateY(90deg) translateZ(75px);
	transform: rotateY(90deg) translateZ(75px);
	background-color: #b40404;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

.OpeningCircle {
	width: 50px;
	height: 50px;
	background-color: #000;
	border-radius: 50%;
}

.TopSide {
	-webkit-transform: rotateX(90deg) translateZ(75px);
	transform: rotateX(90deg) translateZ(75px);
	background-color: #f50606;
	font-size: 20px;
}

.BottomSide {
	-webkit-transform: rotateX(-90deg) translateZ(75px);
	transform: rotateX(-90deg) translateZ(75px);
	background-color: #f50606;
	font-size: 20px;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotateX(360deg);
		transform: rotateX(360deg);
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotateX(360deg);
		transform: rotateX(360deg);
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}
}

@media (max-width: 679px) {
	.Layout {
		display: none;
	}
}
