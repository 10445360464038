.DrawerToggle {
	width: 50px;
	height: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	justify-content: space-around;
	box-sizing: border-box;
	padding: 5px 0px;
	margin-left: 5%;
}

.DrawerToggle div {
	width: 80%;
	height: 3px;
	border-radius: 70%;
	background-color: #eee;
}

@media (min-width: 680px) {
	.DrawerToggle {
		display: none;
	}
}
