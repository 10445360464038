.Layout {
	background-color: rgba(135, 207, 235, 0.897);
}

.InfoContainer {
	box-sizing: border-box;
	padding: 0% 5% 2% 5%;
	width: 100%;
	text-align: center;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.Info {
	box-shadow: 35px 35px 20px 5px rgba(0, 0, 0, 0.616);
	margin-bottom: 50px;
	font-weight: bold;
	-webkit-animation: appear 2s ease-in;
	animation: appear 2s ease-in;
	box-sizing: border-box;
	padding: 2%;
	font-size: 4.5vw;
	width: 100%;
	max-width: 850px;
}

.H1Tag {
	text-decoration: underline;
}

.AnchorTags {
	color: #0000ff;
}

@-webkit-keyframes appear {
	0% {
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.616);
	}
	25% {
		box-shadow: 7px 7px 5px 2px rgba(0, 0, 0, 0.616);
	}
	50% {
		box-shadow: 14px 14px 12px 5px rgba(0, 0, 0, 0.616);
	}
	75% {
		box-shadow: 21px 21px 20px 5px rgba(0, 0, 0, 0.616);
	}
	100% {
		box-shadow: 35px 35px 20px 5px rgba(0, 0, 0, 0.616);
	}
}

@keyframes appear {
	0% {
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.616);
	}
	25% {
		box-shadow: 7px 7px 5px 2px rgba(0, 0, 0, 0.616);
	}
	50% {
		box-shadow: 14px 14px 12px 5px rgba(0, 0, 0, 0.616);
	}
	75% {
		box-shadow: 21px 21px 20px 5px rgba(0, 0, 0, 0.616);
	}
	100% {
		box-shadow: 35px 35px 20px 5px rgba(0, 0, 0, 0.616);
	}
}

@media (min-width: 600px) {
	.Info {
		font-size: 28px;
	}
}
