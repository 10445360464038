.NavigationItems {
	list-style: none;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-flow: column;
	margin: 0px;
	padding: 0px;
}

.NavItemChild {
	color: inherit;
	font-size: inherit;
	padding: 0px 14px;
	height: 50px;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

.NavItemChild:hover {
	border-bottom: 3px solid rgb(245, 6, 6);
}

@media (min-width: 680px) {
	.NavigationItems {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-flow: row;
		margin: 0px 0px 0px 50px;
	}

	.NavItemChild {
		font-size: 16px;
	}
}
