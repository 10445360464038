.InnerLayout {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	align-items: center;
	padding-top: 5%;
}

.ToolBarContainer {
	height: 50px;
	width: 75%;
}

.Toolbar {
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(0, 0, 0, 0.85)),
		to(rgba(0, 0, 0, 0.25))
	);
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.85),
		rgba(0, 0, 0, 0.25)
	);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 4;
}

@media (max-width: 900px) {
	.InnerLayout {
		padding-top: 7%;
	}

	.ToolBarContainer {
		width: 68%;
	}
}

@media (max-width: 679px) {
	.InnerLayout {
		padding: 0px;
	}

	.DesktopOnly {
		display: none;
	}

	.ToolBarContainer {
		width: 100%;
		margin-top: 0px;
		padding: 0px;
		height: 50px;
	}

	.Toolbar {
		border-radius: 0px;
	}
}
