.InnerLayout {
	text-align: center;
}

.Modal {
	box-sizing: border-box;
	padding: 10px;
	position: relative;
	z-index: 500;
	width: 90%;
	left: 5%;
	top: 5%;
	border: 2px solid #ccc;
	background-color: #fff;
	border-radius: 10px;
}

.NoModal {
	display: none;
}
