.H1Container {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	background-color: #000;
	color: #ddd;
	margin: 0px;
	height: 100px;
}

.HrLine {
	width: 70%;
	border: 3px solid #ddd;
}

.Parallax,
.ParallaxOne,
.ParallaxTwo,
.ParallaxThree,
.ParallaxFour {
	height: 375px;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.ParallaxOne {
	background-image: url(../../assets/images/Development_1_compressed.jpg);
}

.ParallaxTwo {
	background-image: url(../../assets/images/Development_2_compressed.jpg);
}

.ParallaxThree {
	background-image: url(../../assets/images/Web_Development_1_compressed.jpg);
}

.ParallaxFour {
	background-image: url(../../assets/images/Responsive_Application_1_compressed.jpg);
}

.DescriptionContainer {
	box-sizing: border-box;
	padding: 2%;
	background-image: linear-gradient(225deg, #ffff00, #0066ff);
}

.Description {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	height: 400px;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
}

@supports (-webkit-touch-callout: none) {
	/* CSS specific to iOS devices */
	.Parallax,
	.ParallaxOne,
	.ParallaxTwo,
	.ParallaxThree,
	.ParallaxFour {
		background-attachment: scroll;
	}
}

/* @supports not (-webkit-touch-callout: none) {
	CSS for other than iOS devices
} */

@media (min-width: 450px) {
	.Description {
		font-size: 24px;
	}
}

@media (min-width: 550px) {
	.Description {
		font-size: 30px;
	}
}
