.Footer {
	width: 100%;
	height: 320px;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: space-evenly;
	justify-content: space-evenly;
	-webkit-box-align: center;
	align-items: center;
}

.LogoContainer {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

.FooterLogoContainer {
	width: 100%;
}

.FooterLogo {
	width: 90px;
	height: 80px;
	margin: 50px 25px 50px 25px;
}

.FooterNote {
	box-sizing: border-box;
	padding: 20px;
	font-size: 22px;
}
