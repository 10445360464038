.H1PhotoTag {
	margin: 0px;
	text-align: center;
	background-color: #87cfebe5;
}

.PhotoGalleryContainer {
	background-color: #87cfebe5;
	-webkit-animation: extend 2s;
	animation: extend 2s;
}

.GalleryMessage,
.GalleryMessageTwo {
	font-size: 18px;
	font-weight: bold;
	margin: 0px auto;
	text-align: center;
}

.GalleryMessageTwo {
	font-size: 16px;
}

.PhotoGallery {
	box-sizing: border-box;
	display: -webkit-box;
	display: flex;
	overflow-x: scroll;
	height: 350px;
}

.BigPicture {
	height: 304px;
	min-width: 300px;
	border: 3px solid #eee;
}

.BigPicture:hover {
	border: 3px solid #0000ff;
}

.NormalPhotoContainerTop {
	display: -webkit-box;
	display: flex;
	flex-wrap: nowrap;
	flex-shrink: 0;
}

.NormalPhotoContainerBottom {
	display: -webkit-box;
	display: flex;
	flex-shrink: 0;
	flex-wrap: nowrap;
}

.NormalPicture {
	width: 300px;
	height: 150px;
	display: inline-block;
	border: 3px solid #eee;
}

.NormalPicture:hover {
	border: 3px solid #0000ff;
}

@-webkit-keyframes extend {
	0% {
		-webkit-transform: translateX(150%);
		transform: translateX(150%);
	}
	25% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@keyframes extend {
	0% {
		-webkit-transform: translateX(150%);
		transform: translateX(150%);
	}
	25% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@media (min-width: 460px) {
	.BigPicture {
		min-width: 400px;
	}
}
