.Layout {
	overflow-y: hidden;
	scroll-behavior: smooth;
}

.ModalContainer {
	background-color: #87cfebe5;
}

.Video {
	width: 85%;
}

.DisplayImage {
	width: 100%;
	height: 100%;
	max-height: 1000px;
}

.Images {
	width: 100%;
	height: 100%;
}

.Images:hover {
	cursor: pointer;
}

.H1VideoTag {
	margin: 0px;
	text-align: center;
	background-color: #87ceeb;
	padding-top: 3%;
}

.VideoThumbnailMessage {
	font-size: 18px;
	font-weight: bold;
	margin: 0px;
	text-align: center;
	background-color: #87ceeb;
}

.VideoThumbnailContainer {
	background-color: #87ceeb;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	box-sizing: border-box;
	padding: 0% 5% 5% 5%;
}

.VideoThumbnailColumn {
	width: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
}

.VideoThumbnail {
	width: 100%;
	max-width: 500px;
	height: 250px;
	margin: 10% 5% 1% 5%;
}

.VideoThumbnail:hover {
	cursor: pointer;
}

.VideoThumbnailDescription {
	width: 100%;
	max-width: 500px;
	height: 100px;
	font-weight: bold;
}

@-webkit-keyframes extend {
	0% {
		-webkit-transform: translateX(150%);
		transform: translateX(150%);
	}
	25% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@keyframes extend {
	0% {
		-webkit-transform: translateX(150%);
		transform: translateX(150%);
	}
	25% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@media (min-width: 500px) {
	.VideoThumbnail {
		height: 300px;
	}
}

@media (min-width: 1200px) {
	.VideoThumbnailContainer {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-direction: row;
	}
}
