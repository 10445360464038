.PositionPentagonalPrism {
	width: 100%;
	height: 500px;
	background-color: #87cfebe5;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
}

.PentagonalPrismContainer {
	width: 360px;
	height: 360px;
	-webkit-perspective: 800px;
	perspective: 800px;
}

.PentagonalPrism {
	width: 360px;
	height: 360px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-animation-name: rotate;
	animation-name: rotate;
	-webkit-animation-duration: 15s;
	animation-duration: 15s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0.02;
	animation-delay: 0.02;
}

.PentagonalPrism div {
	position: absolute;
	width: 360px;
	height: 360px;
}

.Front {
	-webkit-transform: translateZ(246px);
	transform: translateZ(246px);
}

.FrontReverse {
	-webkit-transform: translateZ(242px) rotateY(180deg);
	transform: translateZ(242px) rotateY(180deg);
	background-color: orange;
}

.LeftFront {
	-webkit-transform: translateZ(245px) rotateY(-90deg) translateX(-180px)
		translateZ(180px) rotateY(18deg) translateZ(56px) translateX(-8px);
	transform: translateZ(245px) rotateY(-90deg) translateX(-180px)
		translateZ(180px) rotateY(18deg) translateZ(56px) translateX(-8px);
}

.LeftFrontReverse {
	-webkit-transform: translateZ(245px) rotateY(-90deg) translateX(-180px)
		translateZ(180px) rotateY(18deg) translateZ(52px) translateX(-8px)
		rotateY(180deg);
	transform: translateZ(245px) rotateY(-90deg) translateX(-180px)
		translateZ(180px) rotateY(18deg) translateZ(52px) translateX(-8px)
		rotateY(180deg);
	background-color: red;
}

.LeftRear {
	-webkit-transform: translateZ(245px) rotateY(-180deg) rotateY(36deg)
		translateZ(447px) translateX(-145px);
	transform: translateZ(245px) rotateY(-180deg) rotateY(36deg)
		translateZ(447px) translateX(-145px);
}

.LeftRearReverse {
	-webkit-transform: translateZ(245px) rotateY(-180deg) rotateY(36deg)
		translateZ(443px) translateX(-145px) rotateY(180deg);
	transform: translateZ(245px) rotateY(-180deg) rotateY(36deg)
		translateZ(443px) translateX(-145px) rotateY(180deg);
	background-color: purple;
}

.RightFront {
	-webkit-transform: translateZ(245px) rotateY(90deg) translateX(180px)
		translateZ(180px) rotateY(-18deg) translateZ(56px) translateX(8px);
	transform: translateZ(245px) rotateY(90deg) translateX(180px)
		translateZ(180px) rotateY(-18deg) translateZ(56px) translateX(8px);
}

.RightFrontReverse {
	-webkit-transform: translateZ(245px) rotateY(90deg) translateX(180px)
		translateZ(180px) rotateY(-18deg) translateZ(52px) translateX(8px)
		rotateY(180deg);
	transform: translateZ(245px) rotateY(90deg) translateX(180px)
		translateZ(180px) rotateY(-18deg) translateZ(52px) translateX(8px)
		rotateY(180deg);
	background-color: chartreuse;
}

.RightRear {
	-webkit-transform: translateZ(245px) rotateY(180deg) rotateY(-36deg)
		translateZ(447px) translateX(145px);
	transform: translateZ(245px) rotateY(180deg) rotateY(-36deg)
		translateZ(447px) translateX(145px);
}

.RightRearReverse {
	-webkit-transform: translateZ(245px) rotateY(180deg) rotateY(-36deg)
		translateZ(443px) translateX(145px) rotateY(180deg);
	transform: translateZ(245px) rotateY(180deg) rotateY(-36deg)
		translateZ(443px) translateX(145px) rotateY(180deg);
	background-color: aqua;
}

.PentagonalPrismImages {
	width: 100%;
	height: 100%;
	display: block;
	border: 1px solid #000;
}

.Container {
	width: 100%;
	box-sizing: border-box;
	padding: 75px 10%;
	background-color: #87ceeb;
}

.NewProject {
	width: 100%;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}

.Images {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
	border: 10px solid #fff;
	border-radius: 10px;
	box-shadow: 0px 0px 5px 5px #000;
}

.ImageDescriptionContainer {
	margin-bottom: 100px;
}

.ImageDescription {
	font-size: 20px;
	text-align: center;
	font-weight: bold;
	margin: 0px;
	background-color: #87ceeb;
}

.BottomNote {
	font-size: 22px;
	font-weight: bold;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}
	20% {
		-webkit-transform: rotateX(360deg);
		transform: rotateX(360deg);
	}
	21% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
	100% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}
	20% {
		-webkit-transform: rotateX(360deg);
		transform: rotateX(360deg);
	}
	21% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
	100% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@-webkit-keyframes safariBrowserRotate {
	0% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
	100% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@keyframes safariBrowserRotate {
	0% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
	100% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

/* Safari 10.1+ */
/* Yes extra media is required */
@media not all and (min-resolution: 0.001dpcm) {
	@media {
		.PentagonalPrism {
			-webkit-animation-name: safariBrowserRotate;
			animation-name: safariBrowserRotate;
			-webkit-animation-duration: 12s;
			animation-duration: 12s;
		}
	}
}

@media (max-width: 679px) {
	.PositionPentagonalPrism {
		display: none;
	}

	.Container {
		padding: 50px 10%;
	}

	.ImageDescription {
		font-size: 18px;
	}
}
