.NavigationItem {
	background-color: rgba(0, 0, 0, 0.75);
	margin: 2% 0%;
}

.NavigationItem:hover {
	background-color: rgba(0, 0, 0, 0.6);
}

.NavLink {
	/* Style Nav child separately */
	text-decoration: none;
	font-size: 16px;
	color: #fff;
	display: block;
}

.CurrentPath {
	/* Only main attributes that are going to change between active and inactive goes here */
	background-color: rgb(245, 6, 6);
	color: #000;
	font-size: 18px;
	font-weight: bold;
	padding-bottom: 3px;
	height: 50px;
	-webkit-animation: slide 0.5s ease-in;
	animation: slide 0.5s ease-in;
}

@-webkit-keyframes slide {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
	25% {
		-webkit-transform: translateX(15%);
		transform: translateX(15%);
	}
	50% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
	75% {
		-webkit-transform: translateX(-15%);
		transform: translateX(-15%);
	}
	100% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@keyframes slide {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
	25% {
		-webkit-transform: translateX(15%);
		transform: translateX(15%);
	}
	50% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
	75% {
		-webkit-transform: translateX(-15%);
		transform: translateX(-15%);
	}
	100% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@media (min-width: 680px) {
	.NavigationItem {
		background-color: transparent;
		border: none;
		margin: 0%;
	}

	.NavigationItem:hover {
		background-color: rgba(0, 0, 0, 0.2);
	}
}
