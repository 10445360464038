.ModalSection {
	background-color: #87cfebe5;
	padding-bottom: 150px;
}

.MainDisplayBackground {
	width: 100%;
	height: 360px;
	background-color: #87cfebe5;
}

.MainDisplayImage {
	position: relative;
	z-index: 0;
	top: -100px;
	-webkit-animation: fall 3s linear;
	animation: fall 3s linear;
	width: 90%;
	max-width: 430px;
	height: auto;
	margin: 0px auto;
	display: block;
	border: 5px solid rgba(0, 0, 0, 0.63);
	border-radius: 10px;
}

.MainDisplayImage:hover {
	cursor: pointer;
	box-shadow: 0px 0px 8px 8px #fff;
}

.ResumeContainer {
	background-color: #87ceeb;
	width: 100%;
	box-sizing: border-box;
	padding: 2%;
	text-align: center;
	font-size: 30px;
}

.DownloadLabel {
	font-size: 26px;
	display: block;
	font-family: Courier;
}

.Resume {
	margin: 0px auto;
	width: 98%;
	max-width: 850px;
	height: auto;
	border: 3px solid rgba(0, 0, 0, 0.63);
	border-radius: 10px;
}

.presentImage {
	width: 50%;
	height: auto;
	box-shadow: 8px 8px 8px 8px #ddd;
	border-radius: 10px;
}

.Images {
	width: 100%;
	max-width: 300px;
	height: auto;
	margin: 5px;
	border: 3px solid rgba(0, 0, 0, 0.63);
	border-radius: 10px;
}

.Images:hover {
	cursor: pointer;
	box-shadow: 0px 0px 8px 8px #fff;
}

.Layout hr {
	width: 50%;
	border: 1px solid rgb(245, 6, 6);
}

.PdfIcon:hover {
	-webkit-animation: wiggle 0.1s;
	animation: wiggle 0.1s;
	-webkit-animation-iteration-count: 2;
	animation-iteration-count: 2;
	cursor: pointer;
}

@-webkit-keyframes wiggle {
	0% {
		-webkit-transform: rotateZ(-10deg);
		transform: rotateZ(-10deg);
	}
	25% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}
	50% {
		-webkit-transform: rotateZ(10deg);
		transform: rotateZ(10deg);
	}
	75% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}
}

@keyframes wiggle {
	0% {
		-webkit-transform: rotateZ(-10deg);
		transform: rotateZ(-10deg);
	}
	25% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}
	50% {
		-webkit-transform: rotateZ(10deg);
		transform: rotateZ(10deg);
	}
	75% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}
}

@-webkit-keyframes fall {
	0% {
		-webkit-transform: rotateZ(-120deg);
		transform: rotateZ(-120deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	20% {
		-webkit-transform: rotateZ(-20deg);
		transform: rotateZ(-20deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	40% {
		-webkit-transform: rotateZ(-120deg);
		transform: rotateZ(-120deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	60% {
		-webkit-transform: rotateZ(-20deg);
		transform: rotateZ(-20deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	66% {
		-webkit-transform: rotateZ(-5deg);
		transform: rotateZ(-5deg);
		top: -250px;
		left: -30%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	85% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
		top: -150px;
		left: 30%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	95% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
		top: -100px;
		left: 0%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}

@keyframes fall {
	0% {
		-webkit-transform: rotateZ(-120deg);
		transform: rotateZ(-120deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	20% {
		-webkit-transform: rotateZ(-20deg);
		transform: rotateZ(-20deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	40% {
		-webkit-transform: rotateZ(-120deg);
		transform: rotateZ(-120deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	60% {
		-webkit-transform: rotateZ(-20deg);
		transform: rotateZ(-20deg);
		top: -450px;
		left: -20%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	66% {
		-webkit-transform: rotateZ(-5deg);
		transform: rotateZ(-5deg);
		top: -250px;
		left: -30%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	85% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
		top: -150px;
		left: 30%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	95% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
		top: -100px;
		left: 0%;
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}

@media (min-width: 1000px) {
	.Layout hr {
		width: 20%;
	}
}
